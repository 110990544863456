import {
  Radio,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';

export default function RadioGroup(props) {
  return (
    <>
      <FormLabel>{props.label}</FormLabel>
      <MuiRadioGroup {...props}>
        {props.options?.map((x) => (
          <FormControlLabel
            defaultChecked={x.value === 'BOOK'}
            key={x.value}
            control={<Radio />}
            label={x.label}
            value={x.value}
          />
        ))}
      </MuiRadioGroup>
    </>
  );
}
